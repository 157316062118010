export const tips = [
  'Utilisez une adresse mail professionnelle.',
  'Mettez à jour vos informations de contact.',
  'Utilisez une taille de caractères appropriée.',
  'Utilisez un ordre anté-chronologique pour vos expériences professionnelles.',
  'Alignez vos différents contenus à gauche.',
  'Faites un usage stratégique des caractères gras, italiques ...',
  'Choisissez une police de caractères agréable et lisible.',
  'Donnez des sous-titres simples à vos expériences.',
  'Ajoutez des URLs à votre profil , sites web personnels ou vos blogs.',
];

export const getRandomTip = () => {
  const tip = tips[Math.floor(Math.random() * tips.length)];
  const index = tips.indexOf(tip) + 1;
  return `Tip #${index}: ${tip}`;
};
